<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top: 65px; padding: 5px">
    <v-container fluid style="position:relative;">
      <div v-if="config == null">...</div>
      <div v-else v-for="(controller, index) in controllers" :key="index">
        <table class="h-screen mb-10" fixed-header>
          <thead>
            <tr>
              <th class="text-left w-50" fixed>
                {{ $t('notifications.controllers.' + controller) }}
              </th>
              <th class="text-center greenbg colWidth text-no-wrap pa-2">
                <span v-if="!index">{{ $t('general.app_name') }}<br /></span>
                <v-icon>mdi-bell</v-icon>
              </th>
              <th class="text-center colWidth pa-2">
                <span v-if="!index">{{ $t('notifications.email') }}<br /></span>
                <v-icon>mdi-at</v-icon>
              </th>
              <th class="text-center colWidth greenbg pa-2">
                <span v-if="!index">{{ $t('notifications.desktop') }}<br /></span>
                <v-icon>mdi-desktop-classic</v-icon>
              </th>
              <th class="text-center colWidth pa-2">
                <span v-if="!index">{{ $t('notifications.mobile') }}<br /></span>
                <v-icon>mdi-cellphone</v-icon>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cfgitem in items(controller)" :key="cfgitem.id">
              <td class="px-5 py-2 text-no-wrap text-right" style="width:300px !important;">{{ $t('notifications.type.' + cfgitem.notification_type.name) }}
                <div v-if="cfgitem.notification_type.name == 'status_change'" style="width:260px !important;" :set="st=getStatusText(cfgitem.notification_type.controller)">
                  <v-select
                            v-model="cfgitem.params.statusTo"
                            :items="Object.keys(st).map(k => st[k]).sort((a, b) => a.index - b.index)"
                            item-text="text"
                            item-value="id"
                            multiple
                            @change="changeValue(cfgitem, 'params');"
                            width="50px;">
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 2" color="#FFFFFF"
                              :text-color="getStatusColor(cfgitem.notification_type.controller)[item.id].text">
                        <span>{{ item.text }}</span>
                      </v-chip>
                      <span
                            v-if="index === 2"
                            class="grey--text text-caption">
                        (+{{ cfgitem.params.statusTo.length - 2 }})
                      </span>
                    </template>
                  </v-select>
                </div>
                <div v-if="cfgitem.notification_type.name == 'installation_passed'" style="width:260px !important;">
                  <v-text-field
                                style="
                    display: inline-block;
                    width: 33.333%;
                    background-color: white;
                  "
                                type="number"
                                dense
                                outlined
                                v-model="cfgitem.params.days"
                                @wheel="$event.target.blur()"
                                @keypress="numberOnlyValidation($event)"
                                @change="if (cfgitem.params.days < 1) cfgitem.params.days = 1; changeValue(cfgitem, 'params');"
                                hide-details="auto"
                                oninput="if (this.value && this.value.length > this.maxLength) { this.value=this.value.slice(0,this.maxLength) } else if (this.value < 1) this.value = 1; else if (this.value > 365) this.value = 365;"
                                maxlength="3"></v-text-field>
                  {{ $tc('reportInfo.day', 2) }}
                </div>
              </td>
              <td class="px-5 py-2 text-center greenbg"><v-checkbox v-model="cfgitem.bell" hide-details
                            class="d-inline-flex mr-0 ml-2 mt-0" @change="changeValue(cfgitem, 'bell')"></v-checkbox>
              </td>
              <td class="px-5 py-2 text-center"><v-checkbox v-model="cfgitem.email" hide-details
                            class="d-inline-flex mr-0 ml-2 mt-0"
                            @change="changeValue(cfgitem, 'email')"></v-checkbox></td>
              <td class="px-5 py-2 text-center greenbg"><v-checkbox v-model="cfgitem.desktop" hide-details
                            class="d-inline-flex mr-0 ml-2 mt-0" @change="changeValue(cfgitem, 'desktop')"></v-checkbox>
              </td>
              <td class="px-5 py-2 text-center body-2"><!--<v-checkbox v-model="cfgitem.mobile" hide-details
                            class="d-inline-flex mr-0 ml-2 mt-0"
                            @change="changeValue(cfgitem, 'mobile')"></v-checkbox>-->{{ $tc('general.comingSoon') }}</td>
            </tr>
          </tbody>
        </table>
        <hr /><br />
      </div>
    </v-container>

    <v-dialog v-model="configChangedRemotely" max-width="600" persistent>
      <v-card>
        <v-card-title>
          {{ $t('notifications.configChanged.title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('notifications.configChanged.message') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="loadUserConfig();">{{ $t("notifications.configChanged.btn")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { SITE_REPORT_STATUS_TEXT, SITE_REPORT_STATUS_COLOR, PROJECT_STATUS_TEXT, PROJECT_STATUS_COLOR, DOCUMENT_REQUEST_STATUS_TEXT, DOCUMENT_REQUEST_STATUS_COLOR, SITE_REPORT_STATUS, DOCUMENT_REQUEST_STATUS, PROJECT_STATUS } from "@/enums.js";
import NotificationService from "@/services/notification.service";
import { numberOnlyValidation, groupBy } from "@/utils/utils"

export default {
  name: "NotificationConfig",
  components: {
  },
  data: () => {
    return {
      SITE_REPORT_STATUS_TEXT: SITE_REPORT_STATUS_TEXT,
      SITE_REPORT_STATUS_COLOR: SITE_REPORT_STATUS_COLOR,
      SITE_REPORT_STATUS: SITE_REPORT_STATUS, 
      DOCUMENT_REQUEST_STATUS: DOCUMENT_REQUEST_STATUS, 
      PROJECT_STATUS: PROJECT_STATUS,
      PROJECT_STATUS_TEXT: PROJECT_STATUS_TEXT,
      PROJECT_STATUS_COLOR: PROJECT_STATUS_COLOR,
      DOCUMENT_REQUEST_STATUS_TEXT: DOCUMENT_REQUEST_STATUS_TEXT,
      DOCUMENT_REQUEST_STATUS_COLOR: DOCUMENT_REQUEST_STATUS_COLOR,
      config: null,
      configChangedRemotely: false,
      numberOnlyValidation: numberOnlyValidation,
      groupBy: groupBy
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      controllers() {
        const controllers = new Set();
        this.config.forEach(config => controllers.add(config.notification_type.controller));
        return Array.from(controllers);
      }
    })
  },
  methods: {
    ...mapMutations({
      showErrorMessage: 'showErrorMessage',
      openToast: "openToast"
    }),
    ...mapActions({
    }),
    addIndex(indexObj, textObj) {
      let idx = 0;
      let result = {};
      for (let key in indexObj) {
        result[key] = {id: indexObj[key], text: this.$t(textObj[indexObj[key]]), index: idx++};
      }
      return result;
    },
    getStatusText(controller) {
      if (controller == 'report') return this.addIndex(Object.fromEntries(Object.entries(SITE_REPORT_STATUS).filter(([key]) => key !== "DELETED")), SITE_REPORT_STATUS_TEXT);
      if (controller == 'documentRequest') return this.addIndex(DOCUMENT_REQUEST_STATUS, DOCUMENT_REQUEST_STATUS_TEXT);
      return this.addIndex(Object.fromEntries(Object.entries(PROJECT_STATUS).filter(([key]) => key !== "DELETED")), PROJECT_STATUS_TEXT);
    },
    getStatusColor(controller) {
      if (controller == 'report') return SITE_REPORT_STATUS_COLOR;
      if (controller == 'documentRequest') return DOCUMENT_REQUEST_STATUS_COLOR;
      return PROJECT_STATUS_COLOR;
    },
    items(item) {
      return this.config
        .filter(e => e.notification_type.controller === item)
      //.map(e => e.model);
    },
    async notificationRequest() {
      if (!window.Notification) {
        console.log('Browser does not support notifications.');
      } else {
        // check if permission is already granted
        if (Notification.permission !== 'granted') {
          // request permission from user
          let permission = await Notification.requestPermission();
          if (permission !== 'granted') {
            this.showErrorMessage(this.$t('notifications.errors.permissionRequest'));
          }
        }
      }
    },
    async loadUserConfig() {
      this.config = null;
      this.configChangedRemotely = false;
      this.config = await NotificationService.getConfig();
    },
    async changeValue(item, changedValue) {
      if (changedValue == 'desktop') this.notificationRequest();
      let result = await NotificationService.setConfig(item, changedValue);
      if (typeof result === 'string') this.showErrorMessage(this.$t(result));
    }
  },
  async mounted() {
    this.loadUserConfig();
    this.$socket.on('notificationConfigUpdated', () => {
      this.configChangedRemotely = true;
    });
  },
};
</script>

<style scoped>
.greenbg {
  background-color: #009F4022;
}

.colWidth {
  width: 85px;
}
</style>